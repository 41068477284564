import formatDate from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import { da } from 'date-fns/locale';
export default function toDateString(
  value: string | Date,
  format?: string
): string {
  const dateFormat: string = format ? format : 'dd-MM-yyyy';
  if (!value) {
    return '';
  }

  const date: Date = typeof value === 'string' ? parseISO(value) : value;
  if (isValid(date)) {
    if (isAfter(date, new Date(1900, 1, 1))) {
      return formatDate(date, dateFormat, { locale: da });
    }
    return '';
  } else {
    return typeof value === 'string' ? value : value.toString();
  }
}
